import Vue from "vue";

export default class WpsVue {
  constructor(options) {
    this.options = options;
    if (process.env.NODE_ENV === "development") {
      // TODO: 测试代码
      new Vue(this.options).$mount("#app");
    } else {
      // TODO: 正式代码, 保证 SDK 加载完毕之后再初始化 Vue 实例
      this.init();
    }
  }

  init = () => {
    if (!window.wps) {
      console.log("不在WPS");
      document.addEventListener("WpsofficeSDKReady", this.wpsDefaultInit, false);
    } else {
      console.log("在WPS");
      this.wpsDefaultInit();
    }
  };

  wpsDefaultInit = () => {
    console.log("WPS SDK 开始加载");
    new Vue(this.options).$mount("#app");
    console.log("WPS SDK 已加载完成");
  };
}
