const bridge = {};

// 网络请求
bridge.httpRequest = ({ method, url, header, params }) =>
  new Promise((resolve, reject) => {
    window.wps.httpRequest({
      method: method || "GET", // 方法 GET
      url,
      header: header || {},
      params: params || {},
      complete: res => {
        if (res.code === 0) {
          resolve(res.data);
        } else {
          reject(res.data);
        }
      }
    });
  });

// 检测当前用户是否登录
bridge.login = () =>
  new Promise((resolve, reject) => {
    window.wps.login({
      complete: res => {
        if (res.code === 0) {
          resolve(res.code);
        } else {
          reject("error code :" + res.code + "error message :" + res.error_msg);
        }
      }
    });
  });

// 登出当前用户
bridge.logout = () =>
  new Promise(resolve => {
    window.wps.logout({
      complete: res => {
        resolve(res);
      }
    });
  });

// 去登录
bridge.isLogin = () =>
  new Promise(resolve => {
    window.wps.isLogin({
      complete: res => {
        resolve(res.data.is_login);
      }
    });
  });

// 获取用户信息
bridge.getUserInfo = () =>
  new Promise((resolve, reject) => {
    window.wps.getUserInfo({
      complete: res => {
        if (res.code === 0) {
          resolve(res);
        }
        reject(res);
      }
    });
  });

// 用户授权
bridge.authorize = () =>
  new Promise((resolve, reject) => {
    window.wps.authorize({
      scope: "scope.userInfo",
      complete: res => {
        if (res.code === 0) {
          resolve(res.status);
        }
      },
      fail: res=> {
        reject(res)
      }
    });
  });

// 获取当前小程序版本
bridge.getAppVersion = () =>
  new Promise(resolve => {
    window.wps.getAppVersion({
      complete: res => {
        if (res.code === 0) {
          resolve(res.data);
        }
      }
    });
  });

// 获取系统信息
bridge.getSystemInfo = () =>
  new Promise((resolve, reject) => {
    window.wps.getSystemInfo({
      complete: res => {
        if (res.code === 0) {
          resolve(res.data);
        }
        reject(res);
      }
    });
  });

// 检测是否是WPS会员
bridge.isWpsVip = () =>
  new Promise(resolve => {
    window.wps.isWpsVip({
      complete: res => {
        resolve(res.data.is_wps_vip);
      }
    });
  });

// 检测是否是稻壳会员
bridge.isDocerVip = () =>
  new Promise(resolve => {
    window.wps.isDocerVip({
      complete: res => {
        resolve(res.data.is_docer_vip);
      }
    });
  });

// 检测是否是超级会员
bridge.isSuperVip = () =>
  new Promise(resolve => {
    window.wps.isSuperVip({
      complete: res => {
        resolve(res.data.is_super_vip);
      }
    });
  });

/**
 * @description: 上报埋点
 * @param event  事件名
 * @param attributes 事件参数 （其中包括position、func_name、data1、data2等等）
 */
bridge.dataCollect = (event, param) => {
  const attributes = {
    func_name: "invoice",
    position: sessionStorage.app_position,
    comp: "xcx",
    ...param
  };
  window.wps.dataCollect({
    event,
    attributes
  });
  const msg = `埋点：event:${event},参数:${JSON.stringify(attributes)}`;
  return msg;
};

bridge.chooseImage = ({ count, sourceType, quality, targetSize, complete }) => {
  window.wps.chooseImage({
    count,
    sourceType,
    quality,
    targetSize,
    complete
  });
};

bridge.selectFiles = ({ title, fileTypes, maxCount, complete }) => {
  window.wps.selectFiles({
    title,
    fileTypes,
    maxCount,
    complete
  });
};

bridge.getImageBase64 = ({ filePath, complete }) => {
  window.wps.getImageBase64({
    filePath,
    complete
  });
};

bridge.saveFile = ({ url, fileName }) =>
  new Promise(resolve => {
    window.wps.saveFile({
      url,
      fileName,
      fileType: "xls",
      complete: res => {
        resolve(res);
      }
    });
  });

bridge.openFile = filePath => {
  return new Promise(resolve => {
    window.wps.openFile({
      filePath,
      complete: res => {
        resolve(res);
      }
    });
  });
};

export default bridge;
