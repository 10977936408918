<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {}
};
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
  position: fixed;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
