import axios from "axios";
import { Message } from "element-ui";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? process.env.VUE_APP_BASE_API : "/", // api 的 base_url
  timeout: 10000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.status;
    if (code < 200 || code > 300) {
      Message.error({
        message: response.message
      });
      return Promise.reject("error");
    } else {
      return response.data;
    }
  },
  error => {
    let code = 0;
    try {
      code = error.response.data.status;
    } catch (e) {
      if (error.toString().indexOf("Error: timeout") !== -1) {
        // Message.warning({
        //   message: "人太多了，请稍后重试",
        //   duration: 5000
        // });
        this.$router.push({ name: '404',query:{error:true}})
        return Promise.reject(error);
      }
    }
    if (code) {
      const errorMsg = error.response.data.message;
      if (errorMsg !== undefined) {
        Message.error({
          message: errorMsg,
          duration: 5000
        });
      }
    } else {
      // Message.error({
      //   message: "接口请求失败",
      //   duration: 5000
      // });
      this.$router.push({ name: '404',query:{error:true}})
    }
    return Promise.reject(error);
  }
);
export default service;
