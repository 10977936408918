const tools = (() => ({
  /**
   * 当前设备处于哪个端
   * @return {string} android | ios | windows
   */
  getOS() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      return "ios";
    }
    if (/(Android)/i.test(navigator.userAgent)) {
      return "android";
    }
    if (/(Windows NT)/i.test(navigator.userAgent)) {
      return "windows";
    }
    return "unknown";
  },

  /**
   * 是否在wps里面（包括iOS和Android和PC）
   * @return {boolean} true | false
   */
  isWps() {
    const os = this.getOS();
    // eslint-disable-next-line no-undef
    if (
      (os === "android" && window.splash) ||
      (os === "ios" &&
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.handleReq) ||
      (os === "windows" && window.__wpsoffice_pc_miniprogram)
    ) {
      return true;
    }
    return false;
  }
}))();

export default tools;
