<template>
  <div class="home" style="height: 100%;overflow-y: auto"  v-loading="loading" >
<!--    <button @click="goRedirectUrl('testm', 'testname')">http请求</button>-->
    <div class="logDiv" ref="logDiv"></div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Home",
  mounted() {
    if (!window.wps) {  //todo 打包时需要注释
      document.addEventListener("WpsofficeSDKReady", this.ready, false);
    } else {
      this.ready();
    }
    document.title = '跳转中...'

    this.loading = true
  },
  data() {
    return {
      redirecting: false,
      userLoading: false,
      loading: false,
      loginStatus: false
    };
  },
  methods: {
    getParam(name){
       var sUrl = window.location.search.substr(1);
       var r = sUrl.match(new RegExp("(^|&)" + name + "=([^&]*)(&|$)"));
       return (r == null ? null : unescape(r[2]))||this.$route.query[name];
    },
    log(name, msg) {
      const strMsg = JSON.stringify(msg);
      this.$refs.logDiv.innerHTML += `<span style="color:red;">【Log】${name}</span><p>${strMsg}</p>`;
      this.$refs.logDiv.scrollTop = this.$refs.logDiv.scrollHeight;
    },
    async login() {
      await this.$bridge.login();
    },
    async getUser() {
      const that = this;
      if(that.userLoading){
        return
      }
      that.userLoading = true
      this.$bridge.authorize().then(res =>{
        this.$bridge
          .getUserInfo()
          .then(res => {
         /*   this.log("userinfo "+res.userInfo.nickname+" ， time" + (new Date()).getTime())
            this.log(this.getParam("appId"))
            alert(this.getParam("appId"))*/
            that.userLoading = false
            that.goRedirectUrl(res.login_code, res.userInfo.nickname, res.userInfo.picUrl)
          })
          .catch(e => {
            that.userLoading = false
            if (e.code === 1005) {
              this.$message.warning("用户未授权")
            }else{
              console.log("userinfo error",e)
              this.$message.error(e)
            }
          });
      }).catch(e =>{
        that.$message.warning("拒绝授权，无法使用")
        console.log("authorize error",e)
        return
      })

    },
    onLoginStatusChanged() {
      const that = this
      window.wps.onLoginStatusChanged(() => {
        if(that.redirecting){
          return
        }
        that.$bridge
          .isLogin()
          .then(res => {
            // this.log("islogin is "+res)
            if(that.loginStatus!=res){
              // this.log("login has changed "+that.loginStatus)
              window.location.reload()
            }
            if (res) {
              this.getUser();
            }
          })
          .catch(e => {
            console.log("login change error", e);
          });
      });
    },
    offLoginStatusChanged() {
      window.wps.offLoginStatusChanged();
    },
    async goRedirectUrl(loginCode, nickName, avatar) {
      const that = this
      if(that.redirecting){
        return
      }
      that.redirecting = true
      await request({
        url: "https://yjy.wpscdn.cn/author/redirect",
        method: "get",
        params: {
          code: loginCode,
          nickName: encodeURI(nickName),
          templateAppId: this.getParam("appId"),
          avatar:encodeURI(avatar),
          create: this.$route.query.create
        }
      })
      .then(res => {
        that.redirecting = false
        if (res.code === 0) {
          // this.log("redires is ", res.data.redirectUrl)
          window.location.href = "https://"+res.data.redirectUrl
        } else {
          // this.$message.warning("人太多了，请稍后重试")
          this.go404()
        }
      })
      .catch(e => {
        console.log("redirect error",e)
        that.redirecting = false
      });
    },
    ready() {
      this.onLoginStatusChanged();
      this.$bridge
        .isLogin()
        .then(res => {
          this.loginStatus = res
          if (!res) {
            this.login();
          } else {
            this.getUser();
          }
        })
        .catch(e => {
          console.log("ready login error", e);
          // this.$message.warning("人太多了，请稍后重试")
          this.go404()
        });
    },
    go404(){
      this.loading = false
      this.$router.push({ name: '404',query:{error:true}})
    }
  }
};
</script>
<style scoped>
.logDiv {
  flex: 1;
  border-left: 1px solid #cccccc;
  text-align: start;
  overflow-y: scroll;
  word-break: break-all;
}
</style>
