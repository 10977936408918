import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import WpsVue from "./utils/WpsVue";
import tools from "./utils/tools";
import bridge from "./utils/bridge";
import axios from "axios";
import Element from "element-ui";

import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;

Vue.prototype.$tools = tools;
Vue.prototype.$bridge = bridge;
Vue.prototype.$http = axios;
Vue.use(Element);

new WpsVue({
  router,
  render: h => h(App)
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})
