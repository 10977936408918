import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Error from "../views/error/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: '跳转中...'
    }
  },
  {
    path: "/404",
    name: "404",
    component: Error,
    props: { error: false },
    meta: {
      title: '404'
    }
  }
];

const router = new VueRouter({
  routes
});

export default router;
