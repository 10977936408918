<template>
    <div class="">
      <div style="margin-top:250px;width: 100%">
        <img :src="errorPic"/>
      </div>
      <p style="color: #666666;font-weight: 300">服务器开小差，请稍后重试</p>
    </div>
</template>

<script>
export default {
  name: "Error",
  data(){
    return {
      errorPic: require('@/assets/img/404.jpg')
    }
  },
  created() {
    // console.log(this.$route.query.error)
    // if(this.$route.query.error){
    //   this.$message.warning("人太多了，请稍后重试")
    // }
  }
}
</script>

<style scoped>
  .main{
    display: flex;
    height: 100%;

    align-items: center;
    justify-content: center;
  }

  .main img{
    align-items: center;
  }
</style>
